<template>
  <div id="subsidiary-management">
    <full-loader v-show="loading"></full-loader>
    <v-container fluid class="wrapper">
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>
      <!-- label and nav button -->
      <div class="header">
        <h3>Subsidiary Management:</h3>
        <div
          style="
            display: flex;
            font-size: 16px;
            white-space: nowrap;
            font-weight: 600;
          "
        >
          ADD SUBSIDIARY
          <v-icon
            class="ml-2"
            style="font-size: 30px; cursor: pointer; margin-top: -5px"
            large
            color="green darken-2"
            @click="clickAdd()"
          >
            mdi-plus-circle-outline
          </v-icon>
        </div>
      </div>

      <v-layout warp>
        <v-flex sm12 md3></v-flex>
        <v-flex sm12 md6>
          <!-- subsidiary list  -->
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined light>
            <v-container>
              <div
                style="min-height: 30px; text-align: center; font-weight: 700"
              >
                Subsidiary
              </div>
              <v-divider></v-divider>
              <v-list style="height: 70vh; overflow: auto; position: relative">
                <div
                  class="container list-wrapper"
                  style="cursor: pointer"
                  @click="getSubsidaryDetails(subsidiary)"
                  :class="{
                    active:
                      selectedSubsidiary &&
                      selectedSubsidiary._id == subsidiary._id,
                  }"
                  v-for="(subsidiary, i) in allSubsidiary"
                  :key="i"
                >
                  <div class="row">
                    <div class="col-10">{{ subsidiary.name }}</div>
                    <div class="col-1">
                      <v-icon
                        class="ml-2"
                        style="
                          font-size: 30px;
                          cursor: pointer;
                          margin-top: -5px;
                        "
                        large
                        color="blue darken-2"
                        @click="clickEdit(subsidiary)"
                      >
                        mdi-pencil-circle-outline
                      </v-icon>
                    </div>
                    <div class="col-1">
                      <v-icon
                        class="ml-2"
                        style="
                          font-size: 30px;
                          cursor: pointer;
                          margin-top: -5px;
                        "
                        large
                        color="red"
                        @click="deleteAlert(subsidiary)"
                      >
                        mdi-delete-circle
                      </v-icon>
                    </div>
                  </div>
                </div>
              </v-list>
              <div
                v-if="allSubsidiary && allSubsidiary.length == 0"
                class="no-data"
              >
                No Subsidiary
              </div>
            </v-container>
          </v-card>
        </v-flex>

        <!-- Admin list -->
        <!-- <v-flex sm12 md4>
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
            <v-container>
              <div
                style="min-height: 30px; text-align: center; font-weight: 700"
              >
                Admin
              </div>
              <v-divider></v-divider>
              <v-list style="height: 64vh; overflow: auto; position: relative">
                <multiselect
                  v-model="temp_admin"
                  placeholder="Select Admin"
                  :options="usersList"
                  :multiple="true"
                  :taggable="true"
                  label="email"
                  track-by="email"
                  @tag="updateAdmin"
                ></multiselect>
              </v-list>
              <div v-if="temp_admin && temp_admin.length == 0" class="no-data">
                No Admin
              </div>
            </v-container>
          </v-card>
        </v-flex> -->

        <!-- user list -->
        <!-- <v-flex sm12 md4>
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
            <v-container>
              <div
                style="min-height: 30px; text-align: center; font-weight: 700"
              >
                Users
              </div>
              <v-divider></v-divider>
              <v-list style="height: 64vh; overflow: auto; position: relative">
                <multiselect
                  v-model="temp_users"
                  placeholder="Select User"
                  :options="usersList"
                  :multiple="true"
                  :taggable="true"
                  label="email"
                  track-by="email"
                  @tag="updateUser"
                ></multiselect>
              </v-list>
              <div v-if="temp_users && temp_users.length == 0" class="no-data">
                No Users
              </div>
            </v-container>
          </v-card>
        </v-flex> -->
      </v-layout>

      <!-- <div class="btns">
        <button class="reset" @click="reset()">Reset</button>
        <button
          :class="{'btn-submit': checkDisable() == false,'btn-dis' : checkDisable() == true}"
          :disabled="checkDisable()"
          @click="onSubmitSubsidiary()"
        >
          Submit
        </button>
      </div> -->
    </v-container>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="400px">
          <v-card>
            <v-card-title v-if="isAddItem"> Add Subsidiary </v-card-title>
            <v-card-title v-if="!isAddItem"> Edit Subsidiary </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Subsidiary Name*"
                        type="text"
                        v-model="subsidiaryName"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  dialog = false;
                  $refs.form.reset();
                "
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!subsidiaryName"
                @click.prevent="submit"
                @click="clickAddorUpdateSubsidiary()"
              >
                <span v-if="isAddItem">Submit</span>
                <span v-if="!isAddItem">Update</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import Mixins from "@/mixin";
import AdminAPI from "@/services/admin_api";
import Multiselect from "vue-multiselect";
import FullLoader from "../components/full-loader.vue";
import SAlert from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export default {
  name: "subsidiary-management",
  mixins: [Mixins],
  components: {
    Multiselect,
    "full-loader": FullLoader,
  },
  data() {
    return {
      // breadcrumb
      breadcrumbItems: [
        {
          text: "Subsidiary Management",
          disabled: false,
          to: { name: "subsidiary-management" },
          exact: true,
        },
      ],
      usersList: [],
      allSubsidiary: [],
      temp_admin: [],
      temp_users: [],
      temp_admin_ids: [],
      temp_users_ids: [],
      loading: false,
      selectedSubsidiary: null,

      // ========
      isAddItem: true,
      dialog: false,
      dialog1: false,
      subsidiaryName: null,
    };
  },
  computed: {},
  methods: {
    async getAllSubsidiary() {
      this.loading = true;
      let resp = await AdminAPI.get(`/subsidiary`);
      if (resp.data && resp.data.status) {
        this.allSubsidiary = resp.data.data;
        this.loading = false;
        if (this.selectedSubsidiary) {
          let data = this.allSubsidiary.filter(
            (x) => x._id == this.selectedSubsidiary._id
          );
          this.getSubsidaryDetails(data[0]);
        } else if (this.allSubsidiary && this.allSubsidiary.length > 0) {
          this.getSubsidaryDetails(this.allSubsidiary[0]);
        }
      } else {
        this.$swal("Failed!", "Something went wrong!", "error");
      }
    },
    async getAllUsers() {
      this.loading = true;
      let users = await AdminAPI.get(`/listUsersVthSections`);
      this.usersList = users.data;
      this.loading = false;
    },
    updateUser(selectedValues) {
      this.temp_users.push(selectedValues);
    },
    updateAdmin(selectedValues) {
      this.temp_admin.push(selectedValues);
    },
    getSubsidaryDetails(data) {
      this.selectedSubsidiary = data;
      this.temp_users = [];
      this.temp_admin = [];
      if (data.admin_list && data.admin_list.length > 0) {
        let admin = [];
        for (const id of data.admin_list) {
          let res = this.usersList.filter((x) => x._id == id);
          admin.push(res[0]);
        }
        this.temp_admin = admin;
      }
      if (data.user_list && data.user_list.length > 0) {
        let user = [];
        for (const id of data.user_list) {
          let res = this.usersList.filter((x) => x._id == id);
          user.push(res[0]);
        }
        this.temp_users = user;
      }
    },
    async clickAdd() {
      this.dialog = true;
      this.isAddItem = true;
    },
    async clickEdit(data) {
      if (data) {
        this.dialog = true;
        this.isAddItem = false;
        this.subsidiaryName = data.name;
      }
    },
    async clickAddorUpdateSubsidiary() {
      let responce = null;
      this.loading = true;
      if (this.subsidiaryName && this.isAddItem) {
        this.dialog = false;
        this.isAddItem = false;
        let data = {
          name: this.subsidiaryName,
        };
        responce = await AdminAPI.post(`/subsidiary/create`, data);
        if (responce.data && responce.data.status) {
          this.$swal("Created!", "Subsidiary has been created.", "success");
          this.subsidiaryName = null;
          this.getAllSubsidiary();
        } else {
          this.$swal("Failed!", "Something went wrong!", "error");
        }
      } else if (this.subsidiaryName && !this.isAddItem) {
        this.dialog = false;
        let data = {
          name: this.subsidiaryName,
        };
        responce = await AdminAPI.put(
          `/subsidiary/update/${this.selectedSubsidiary._id}`,
          data
        );
        if (responce.data && responce.data.status) {
          this.$swal("Updated!", "Subsidiary has been updated.", "success");
          this.subsidiaryName = null;
          this.getAllSubsidiary();
        } else {
          this.$swal("Failed!", "Something went wrong!", "error");
        }
      }
      this.loading = false;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
    },

    reset() {
      this.temp_users = [];
      this.temp_admin = [];
    },
    async onSubmitSubsidiary() {
      this.loading = true;
      let admin_list = [];
      if (this.temp_admin && this.temp_admin.length > 0) {
        for (const admin of this.temp_admin) {
          if (admin._id) {
            admin_list.push(admin._id);
          }
        }
      }
      let user_list = [];
      if (this.temp_users && this.temp_users.length > 0) {
        for (const user of this.temp_users) {
          if (user._id) {
            user_list.push(user._id);
          }
        }
      }
      if (
        admin_list.length > 0 &&
        user_list.length > 0 &&
        this.selectedSubsidiary
      ) {
        let data = {
          admin_list: admin_list,
          user_list: user_list,
        };
        let responce = await AdminAPI.post(
          `/subsidiary/${this.selectedSubsidiary._id}/createAccess`,
          data
        );
        if (responce.data && responce.data.status) {
          this.loading = false;
          this.$swal("Updated!", "Subsidiary has been updated.", "success");
          this.getAllSubsidiary();
        } else {
          this.loading = true;
          this.$swal("Failed!", "Something went wrong!", "error");
        }
      }
    },
    checkDisable() {
      if (
        this.temp_admin &&
        this.temp_admin.length > 0 &&
        this.temp_users &&
        this.temp_users.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    deleteAlert(data) {
      this.$swal({
        title: "Delete Subsidiary",
        text: `Do you want to delete this subsidiary (${data.name})?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let responce = await AdminAPI.delete(
            `/subsidiary/delete/${data._id}`
          );
          if (responce.data.status) {
            this.$swal("Deleted!", "Subsidiary has been deleted.", "success");
            this.selectedSubsidiary = null;
            this.loading = false;
            this.getAllSubsidiary();
          }
        }
      });
    },
  },
  async mounted() {
    //get zones
    this.loading = true;
    await this.getAllUsers();
    await this.getAllSubsidiary();
    this.loading = false;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.no-data {
  position: absolute;
  top: 45%;
  left: 45%;
}
.list-wrapper:hover {
  background: #aaa;
  color: #fff;
}
.list-wrapper {
  transition: 0.3s;
  border-bottom: 1px solid gray;
  color: #000;
}
.active {
  background: #aaa !important;
  color: #fff !important;
}
.sub-head {
  font-weight: 900;
  margin: 0px 10px;
}
.right-wrapper {
  height: 78vh;
  overflow-y: auto;
}
.wrapper {
  height: 80vh !important;
}
.header {
  display: flex;
  justify-content: space-between;
}
.pull-left {
  margin-left: 0 !important;
}

.subsidiary-input {
  border: 1px solid #000;
  border-radius: 7px;
  margin-top: 10px;
  margin-right: 10px;
  color: #000;
  padding: 5px;
  font-size: 16px;
}

.btns {
  text-align: right;
  margin-right: 1rem;
  margin-top: -6px;
}

.btn-submit {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  height: 30px;
  border-radius: 5px;
  width: 4rem;
  font-size: 14px;
  font-weight: 500;
}
.btn-dis {
  color: #fff;
  background-color: gray;
  border-color: gray;
  height: 30px;
  border-radius: 5px;
  width: 4rem;
  font-size: 14px;
  font-weight: 500;
}
.reset {
  margin-right: 10px;
  color: #fff !important;
  background-color: #dc3545;
  border-color: #dc3545;
  height: 30px;
  border-radius: 5px;
  width: 4rem;
  font-size: 14px;
  font-weight: 500;
}
</style>
